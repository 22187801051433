import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import diamands from "../../assests/diamands.png";
import "./UserVideoCall.scss";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebaseConfig/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import Spinner2 from "../../components/Spinner/Spinner2";

function Book() {
  const navigate = useNavigate();
  const [pricingOptions, setPricingOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPricing = async () => {
      try {
        const pricingRef = collection(db, process.env.REACT_APP_FEATURES_ENABLED, process.env.REACT_APP_VIDEO_CALL_COLLECTION, process.env.REACT_APP_PRICING_COLLECTION);
        
        const pricingSnapshot = await getDocs(pricingRef);

        const pricingData = pricingSnapshot.docs.map((doc) => doc.data());
        
        setPricingOptions(pricingData);
      } catch (error) {
        console.error("Error fetching pricing data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPricing();
  }, []);

  const request = (time, gemsRequired, gemsForRequest) => {
    navigate("/mainback/sendrequestforvideocall", {
      state: { callDuration: time, gemsForCall: gemsRequired, specialRequestGems: gemsForRequest},
    });
  };

  if (loading) return <Spinner2 />;

  if (!pricingOptions.length) return <div>No pricing options available.</div>;

  return (
    <Box>
      {pricingOptions.map((option, index) => (
        <Button
          key={index}
          variant="contained"
          fullWidth
          className="bookbtn button-text-align-start"
          onClick={() => request(option.video_call_mins, option.video_call_gems, option.video_call_specialrequest_gems)}
        >
          {`${option.video_call_mins} Min Call`} @ {option.video_call_gems}
          <img
            alt=""
            src={diamands}
            style={{ height: "20px", marginLeft: "10px" }}
          />
        </Button>
      ))}
    </Box>
  );
}

export default Book;
